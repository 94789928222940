<template>
  <div class="flex justify-start items-center">
    <component
      :is="rating >= range ? 'HeartYellow' : 'HeartGray'"
      v-for="(range, key) in ranges"
      :key="key"
      class="w-5 h-5 mr-1"
    />
  </div>
</template>

<script>
import HeartGray from "@/assets/svg/heart-gray.svg?inline";
import HeartYellow from "@/assets/svg/heart-yellow.svg?inline";

export default {
  components: {
    HeartGray,
    HeartYellow,
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ranges: [0.5, 1.5, 2.5, 3.5, 4.5],
    };
  },
};
</script>
