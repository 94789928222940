<template>
  <component
    :is="clickable ? 'RouterLink' : 'div'"
    class="game-card my-4 flex relative overflow-hidden"
    :to="`/game/${game.id}`"
  >
    <div class="relative flex flex-shrink-0 w-28 items-center">
      <img
        v-if="game.thumbnail"
        :src="game.thumbnail.downloadUrl"
        :alt="game.name"
        class="w-28 h-full object-cover bg-gray-100"
      />
      <img
        v-else-if="game.lottery"
        :src="game.lottery.logo.downloadUrl"
        :alt="game.name"
        class="w-28 h-full object-cover bg-gray-100"
      />
      <RecommendationBannerForCard :percentile="rankPercentile" :is-retried="isRetried" />
    </div>

    <div class="px-3 py-3 uppercase w-full min-h-28 border-2 border-gray-200 border-l-0">
      <div class="inline-block text-gray-800 leading-none font-semibold mb-1">
        <span class="text-lg mr-1 leading-tight">
          <template v-if="!isRetried && game.rank">{{ game.rank }}. </template>
          <span :class="{ 'line-through': isRetried }">{{ game.name }}</span>
        </span>
        <span v-if="game.lotteryID" class="text-xs leading-tight"> #{{ game.lotteryID }}</span>
      </div>
      <div class="flex justify-start items-center mb-1">
        <Rating :rating="game.rating" />
        <TrendUpIcon
          v-if="isDetailsPages && game.trend"
          class="w-6 h-6 ml-3"
          :class="{
            'text-green-default': game.trend === 'up',
            'text-red-400 transform rotate-90': game.trend === 'down',
          }"
        />
      </div>

      <div class="text-md leading-tight">
        <span class="game-price mr-2 leading-tight">{{ game.price | formatMoney }}</span>
        <span class="leading-tight">|</span>
        <span class="ml-2 leading-tight"
          >Top prize:
          <template v-if="isRetried">None</template>
          <template v-else>{{ game.topPrize | formatMoney }}</template>
        </span>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import TrendUpIcon from "@/assets/svg/nav/trending-up.svg?inline";
import RecommendationBannerForCard from "@/components/ui/RecommendationBannerForCard.vue";
import Rating from "./ui/Rating.vue";

export default {
  components: { Rating, TrendUpIcon, RecommendationBannerForCard },
  props: {
    game: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["priceFilter"]),
    isDetailsPages() {
      return this.$route.name === "Game";
    },
    isRetried() {
      return this.game.status === "Retried";
    },
    rankPercentile() {
      // Show rank
      return this.priceFilter.length ? this.game.rankByDenominationPercentile : this.game.rankPercentile;
    },
  },
};
</script>
