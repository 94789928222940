<template>
  <div>
    <div v-if="!loading">
      <div class="flex justify-between items-center">
        <h1 class="title-1">{{ title }}</h1>
        <FilterIcon v-if="showFilters" id="show-filters-btn" class="w-6 h-6 text-gray-700" @click="showFilter" />
      </div>

      <SelectedFilters v-if="showFilters" />

      <div v-if="items.length">
        <component
          :is="item.type"
          v-for="(item, key) in items"
          :key="items.type === 'GameCard' ? item.data.id : key"
          :game="item.data"
          :clickable="true"
        />
      </div>
      <Notification v-else message="No results found" class="mt-12" />

      <LazyLoadingAnimation v-if="lazyLoading" />
    </div>
    <LoadingAnimation v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FilterIcon from "@/assets/svg/filter.svg?inline";
import GameCard from "./GameCard.vue";
import LoadingAnimation from "./ui/LoadingAnimation.vue";
import SelectedFilters from "./ui/SelectedFilters.vue";
import Notification from "./ui/Notification.vue";
import AdSense from "./AdSense.vue";
import LazyLoadingAnimation from "./ui/LazyLoadingAnimation.vue";

export default {
  components: { LazyLoadingAnimation, AdSense, Notification, FilterIcon, GameCard, SelectedFilters, LoadingAnimation },
  props: {
    title: {
      type: String,
      required: true,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lazyLoading: false,
      client: process.env.VUE_APP_AD_SENSE_CLIENT,
      slot: process.env.VUE_APP_AD_SENSE_SLOT,
    };
  },
  computed: {
    ...mapGetters(["games", "loading"]),
    items() {
      const items = [];

      this.games.forEach((game, key) => {
        items.push({ data: game, type: "GameCard" });

        if ((key + 1) % 3 === 0 && key > 0) {
          items.push({ data: {}, type: "AdSense" });
        }
      });

      return items;
    },
    skip() {
      return this.games.length;
    },
  },
  async created() {
    await this.fetchGames({ skip: 0 });

    document.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions(["fetchGames"]),
    showFilter() {
      this.$store.commit("UPDATE_SHOW_FILTER_POPUP", true);
    },
    async handleScroll() {
      if (this.lazyLoading) return;
      const { scrollHeight } = document.body;
      const { scrollY, outerHeight } = window;

      // Load content when left one screen before bottom of the page
      if (outerHeight * 2 + scrollY >= scrollHeight) {
        this.lazyLoading = true;
        await this.fetchGames({ skip: this.skip });
        this.lazyLoading = false;
      }
    },
  },
};
</script>
