<template>
  <div v-if="priceFilter.length || goalSort === 1" class="mt-5 mb-6" @click="showFiltersPopup">
    <div class="flex justify-between items-center mb-3 text-sm text-gray-500">
      <div>Filters:</div>
      <div class="underline text-sm text-gray-500 cursor-pointer" @click.stop="resetFilters">Reset filters</div>
    </div>

    <div class="flex flex-wrap mb-3">
      <div
        v-if="goalSort === 1"
        class="inline-flex bg-gray-100 mr-2 mb-2 px-2 py-1 rounded-md font-semibold text-sm text-gray-600"
      >
        Sort by: {{ goal }}
      </div>
      <div
        v-for="(item, key) in priceFilter"
        :key="key"
        class="bg-gray-100 mr-2 mb-2 px-2 py-1 rounded-md font-semibold text-sm text-gray-600 last:mr-0"
      >
        {{ item | formatMoney }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["goalSort", "priceFilter"]),
    goal() {
      return this.goalSort === 1 ? "Top Prize" : "Any Prize";
    },
  },
  methods: {
    ...mapActions(["resetFilters"]),
    showFiltersPopup() {
      this.$store.commit("UPDATE_SHOW_FILTER_POPUP", true);
    },
  },
};
</script>
