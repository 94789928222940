<template>
  <div
    v-if="status"
    class="absolute w-full top-0 w-full h-5 text-sm font-bold text-white flex justify-center items-center uppercase"
    :class="color"
  >
    {{ status }}
  </div>
</template>

<script>
export default {
  props: {
    percentile: {
      type: Number,
      default: null,
    },
    isRetried: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      if (this.isRetried) return "Retried";

      if (this.percentile >= 0 && this.percentile <= 0.1) return "Best Bet!";
      if (this.percentile > 0.1 && this.percentile <= 0.2) return "Good Bet!";
      if (this.percentile > 0.2 && this.percentile <= 0.3) return "Go for it!";

      if (this.percentile > 0.6 && this.percentile <= 0.8) return "Long shot!";
      if (this.percentile > 0.8 && this.percentile <= 1) return "Worst odds!";

      return "";
    },
    color() {
      if (this.isRetried) return "bg-black";

      if (this.percentile >= 0 && this.percentile <= 0.3) return "bg-green-default";
      if (this.percentile > 0.6 && this.percentile <= 1) return "bg-red-500";

      return "";
    },
  },
};
</script>
