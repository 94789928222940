<template>
  <div class="ad-banner">
    <div class="min-h-28 my-4 w-full bg-gray-100">
      <Adsense
        :data-ad-client="client"
        :data-ad-slot="slot"
        :is-new-ads-code="'yes'"
        data-full-width-responsive="yes"
        data-ad-format="auto"
        class="adsbygoogle"
        style="display: block"
        adtest="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  game: {
    type: Object,
    default: () => ({}),
  },
  data() {
    return {
      client: process.env.VUE_APP_AD_SENSE_CLIENT,
      slot: process.env.VUE_APP_AD_SENSE_SLOT,
    };
  },
};
</script>
